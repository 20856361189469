<template>
  <sign-page
    title-text="请假记录"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    table-size="large"
    :tableActionsWidth="210"
    :form-parms-update="formParms"
    :tableActions="tableActions"
    @tableAction="tableAction"
    :tableActionsFixed="true"
    :need-data-file="true">
    <fm-modal title="请假条详细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="{}" :id="chooseData.askLeaveApplyId"></detail>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request,
  statusManageRequest,
  askLeaveApplyRequest
} from '../../api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'

export default {
  components: {
    detail
  },
  created () {
    if (this.$authFunsProxy.detail) {
      this.loadConfig()
    }
  },
  methods: {
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      } else if (parm.action === 'back_off') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定撤销销假吗?'})
        if (result) {
          await request.backOff(this.chooseData.id)
          vm.loadData()
        }
      } else if (parm.action === 'invalid') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定作废吗?'})
        if (result) {
          await request.update(this.chooseData.id, {
            isInvalid: 1
          })
          vm.loadData()
        }
      } else if (parm.action === 'back_invalid') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定撤回作废吗?'})
        if (result) {
          await request.update(this.chooseData.id, {
            isInvalid: 0
          })
          vm.loadData()
        }
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'askLeaveApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      let d = await askLeaveApplyRequest.getType()
      this.applyTypeList = d.map(v => {
        return {
          key: String(v.id),
          label: v.label
        }
      })
      this.config = {}
      this.applyTypeList.forEach(a => {
        this.config[a.key] = c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === a.key))
      })
      this.config['null'] = c.statusSwitchList.filter(v => v.isHost && !v.tag)
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '实际请假类型',
          selectDatas: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {return {key: v, label: v}}),
          key: 'realType',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '实际开始时间',
          key: 'realStartTime',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '实际结束时间',
          key: 'realEndTime',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '实际请假时长',
          key: 'realTimeLong',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '实际请假事由',
          key: 'realReson',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    tableActions () {
      return [{
        key: 'detail',
        label: '假条明细'
      },
      {
        key: 'edit',
        label: '销假',
        show: (data) => {
          return !data.isInvalid && data.status !== 'end'
        }
      },
      {
        key: 'back_off',
        label: '撤回销假',
        show: (data) => {
          return !data.isInvalid && data.status === 'end'
        }
      },
      {
        key: 'invalid',
        label: '作废',
        show: (data) => {
          return !data.isInvalid
        }
      },
      {
        key: 'back_invalid',
        label: '撤回作废',
        show: (data) => {
          return data.isInvalid
        }
      },
      {
        key: 'data_file',
        label: '附件'
      }]
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        field: 'id'
      },
      {
        title: '请假人',
        sort: true,
        field: 'workerName'
      },
      {
        title: '实际类型',
        sort: true,
        field: 'realType',
        filters: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {
          return {
            label: v,
            value: v
          }
        })
      },
      {
        title: '实际开始时间',
        filterRange: true,
        dataType: Date,
        sort: true,
        field: 'realStartTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.realStartTime ? rowData.realStartTime.slice(0, 16) : '-')
        }
      },
      {
        title: '实际结束时间',
        sort: true,
        field: 'realEndTime',
        filterRange: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.realEndTime ? rowData.realEndTime.slice(0, 16) : '-')
        }
      },
      {
        title: '实际请假时长',
        sort: true,
        filterRange: true,
        field: 'realTimeLong'
      },
      {
        title: '实际请假事由',
        sort: true,
        field: 'realReson',
        render: (h, row) => {
          return h(TextCell, {
            props: {
              value: row.realReson
            }
          })
        }
      },
      {
        title: '请假类型',
        sort: true,
        field: 'type',
        filters: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {
          return {
            label: v,
            value: v
          }
        })
      },
      {
        title: '申请时间',
        sort: true,
        filterRange: true,
        dataType: Date,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        filterRange: true,
        dataType: Date,
        sort: true,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        sort: true,
        field: 'endTime',
        filterRange: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        filterRange: true,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson',
        render: (h, row) => {
          return h(TextCell, {
            props: {
              value: row.reson
            }
          })
        }
      },
      {
        title: '状态',
        sort: true,
        field: 'status',
        render: (h, rowData) => {
          let text = rowData.isInvalid ? '已作废' : (rowData.status === 'end' ? '已销假' : '未销假')
          return h('div', text)
        }
      }]
      return data
    }
  },
  data () {
    return {
      request: {
        get: request.get,
        update: request.off
      },
      applyTypeList: [],
      statusMap: {},
      chooseData: null,
      modal: false
    }
  }
}
</script>
